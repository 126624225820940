{
    "login": "ⴰⴽⵛⴰⵎ",
    "login_input": "ⵙⵜⵉ ⴽⵔⴰ ⵏ ⵓⴽⵛⴰⵎ",
    "email": "ⵉⵎⴰⵢⵍ",
    "email_input": "ⵙⵙⵉⴷⴼ ⵉⵎⴰⵢⵍ ⵏⵏⴽ ⴷⴰ",
    "save_changes": "ⵃⴹⵓ ⵉⵙⵏⴼⴰⵍⵏ",
    "password": "ⵉⵏⵉⴳⵍ ⵏ ⵡⴰⴷⴰⴼ",
    "password_input": "ⴰⵔⵉ ⴷⴰ ⵉⵏⵉⴳⵍ ⴰⵎⴰⵢⵏⵓ ⵏ ⵡⴰⴷⴰⴼ",
    "delete": "ⵙⵙⴼⴹ",
    "account": "ⴰⵎⵉⴹⴰⵏ",
    "name": "ⵉⵙⵎ",
    "name_input": "ⵙⵜⵉ ⴽⵔⴰ ⵏ ⵢⵉⵙⵎ",
    "next_coms": "ⵥⵕ ⵉⵅⴼⴰⵡⴰⵍⵏ ⵉⵎⴳⴳⵓⵔⴰ",
    "ago": "{{duration}} ⵉⵣⵔⵉⵏ",
    "previous_coms": "ⵥⵕ ⵉⵅⴼⴰⵡⴰⵍⵏ ⵉⵎⵣⵡⵓⵔⴰ",
    "groups": "ⵜⵉⴳⵔⵓⵎⵎⴰⵡⵉⵏ",
    "login_placeholder": "ⵉⵎⴰⵢⵍ",
    "password_placeholder": "ⵉⵏⵉⴳⵍ ⵏ ⵡⴰⴷⴰⴼ",
    "submit": "ⴰⵣⵏ",
    "new_password_placeholder": "ⵉⵏⵉⴳⵍ ⴰⵎⴰⵢⵏⵓ ⵏ ⵡⴰⴷⴰⴼ",
    "confirm_password_placeholder": "ⵙⴷⴷⵉⴷ ⵉⵏⵉⴳⵍ ⵏ ⵡⴰⴷⴰⴼ",
    "text_placeholder": "ⴰⵔⵉ ⴷⴰ ⵜⴰⴱⵔⴰⵜ ⵏⵏⴽ",
    "upload_music": "ⵔⵏⵓ ⵉⵥⴰⵡⴰⵏⵏ",
    "connect": "ⴰⵡⴰⴹ",
    "title_placeholder": "ⴰⵣⵡⵍ ⵏ ⵜⴱⵔⴰⵜ ⵏⵏⴽ (ⴰⵔⵓⵛⵛⵉⵍ)",
    "upload_image": "ⵔⵏⵓ ⵜⵉⵡⵍⴰⴼⵉⵏ",
    "upload_video": "ⵔⵏⵓ ⵉⴼⵉⴷⵢⵓⵜⵏ",
    "upload_pdf": "ⵔⵏⵓ PDF",
    "add_date": "ⵔⵏⵓ ⴽⵔⴰ ⵏ ⵓⵙⴰⴽⵓⴷ",
    "logout": "ⴼⴼⵖ",
    "settings": "ⵜⵉⵙⵖⴰⵍ",
    "edit": "ⵙⴳⴳⴷ",
    "lang": "ⵜⵓⵜⵍⴰⵢⵜ",
    "ask_delete_message": "ⵉⵙ ⵜⵃⵇⵇⵉⴷ ⴰⴷ ⵜⴽⴽⵙⴷ ⵜⴰⴱⵔⴰⵜ ⴰⴷ?",
    "Invalid login/password": "ⵉⵜⵜⴰⵡⵣⴳⵍ ⵓⴽⵛⴰⵎ ⴷ/ⵏⵉⵖ ⵉⵏⵉⴳⵍ ⵏ ⵡⴰⴷⴰⴼ",
    "forgot_password": "ⵉⵙ ⵜⴻⵜⵜⵓⴷ ⵉⵏⵉⴳⵍ ⵏ ⵡⴰⴷⴰⴼ ⵏⵏⴽ?",
    "User not found": "ⴰⵏⵙⵙⵎⵔⵙ ⵓⵔ ⵉⵍⵍⵉ",
    "group_left": "ⵜⴼⴼⵖⴷ ⵙⴳ ⵜⴳⵔⵓⵎⵎⴰ !",
    "api_key": "ⵜⴰⵙⴰⵔⵓⵜ ⵏ API",
    "replies_other": "{{count}} ⵜⵉⵎⵔⴰⵔⵓⵜⵉⵏ",
    "notifications": "ⵉⵙⵓⴼⵔⵉⵜⵏ",
    "reset_password_title": "ⴰⵍⵙ ⴰⵙⵏⵓⵎ ⵏ ⵉⵏⵉⴳⵍ ⵏ ⵡⴰⴷⴰⴼ ⵏⵏⴽ",
    "passwords_dont_match": "ⵓⵔ ⵉⵎⵙⴰⵙⴰ ⵉⵏⵉⴳⵍ ⵏ ⵡⴰⴷⴰⴼ",
    "invitation_link": "ⴰⵙⵖⵓⵏ ⵏ ⵓⵙⵉⴳⵔ",
    "password_reset_mail_sent": "ⵉⵜⵜⵡⴰⵙⵙⵉⴼⴹ ⵉⵎⴰⵢⵍ ⵅⴼ ⵡⴰⵍⴰⵙ ⵏ ⵓⵙⵔⵓⵙ ⵏ ⵉⵏⵉⴳⵍ ⵏ ⵡⴰⴷⴰⴼ!",
    "error": "ⵜⵍⵍⴰ ⵉⵛⵜ ⵏ ⵜⵣⴳⵍⵜ",
    "reset_invitation_link": "ⴰⵍⵙ ⴰⵙⵏⵓⵎ ⵏ ⵓⵙⵖⵓⵏ ⵏ ⵓⵙⵉⴳⵔ",
    "invitation_welcome": "ⴰⵏⵙⵓⴼ ⵉⵙⴽ ⴳ Zusam ! ⵉⵅⵚⵚⴰ ⴰⴽ ⵓⵣⵎⵎⴻⵎ ⴷⴰⵜ ⵏ ⵓⴽⵛⴰⵎ ⵖⵔ ⵜⴳⵔⵓⵎⵎⴰ.",
    "signup": "ⴰⵣⵎⵎⴻⵎ",
    "create_a_group": "ⵙⴽⵔ ⴽⵔⴰ ⵏ ⵜⴳⵔⵓⵎⵎⴰ",
    "create_the_group": "ⵙⴽⵔ ⵜⴰⴳⵔⵓⵎⵎⴰ",
    "quit_group": "ⴼⴼⵖ ⵙⴳ ⵜⴳⵔⵓⵎⵎⴰ",
    "settings_updated": "ⵜⵜⵓⵙⵎⴰⵢⵏⵓⵏⵜ ⵜⵙⵖⴰⵍ ⵏⵏⴽ",
    "group_updated": "ⵜⴻⵜⵜⵓⵙⵎⴰⵢⵏⵓ ⵜⴳⵔⵓⵎⵎⴰ ⵏⵏⴽ !",
    "publish_in_group": "ⴼⵙⵔ ⴳ ⵜⴳⵔⵓⵎⵎⴰ",
    "search_in_group": "ⵔⵣⵓ ⴳ ⵜⴳⵔⵓⵎⵎⴰ",
    "changed_group_name": "ⵜⵙⵏⴼⵍⴷ ⵉⵙⵎ ⵏ ⵜⴳⵔⵓⵎⵎⴰ",
    "to": "ⵉ",
    "mark_all_as_read": "ⵕⵛⵎ ⵎⴰⵕⵕⴰ ⴰⵎ ⵉⵜⵜⵡⴰⵖⵔⵏ",
    "cancel": "ⵙⵙⵔ",
    "notification_emails": "ⵉⵙⵓⴼⵔⵉⵜⵏ ⵏ ⵉⵎⴰⵢⵍ",
    "none": "ⵓⵀⵓ",
    "hourly": "ⴽⵓ ⵜⴰⵙⵔⴰⴳⵜ",
    "daily": "ⴽⵓ ⴰⵙⵙ",
    "weekly": "ⴽⵓ ⵉⵎⴰⵍⴰⵙⵙ",
    "monthly": "ⴽⵓ ⴰⵢⵢⵓⵔ",
    "has_joined": "ⵉⴽⵛⵎ",
    "search_without_result": "ⵓⵔ ⵉⵎⵙⴰⵙⴰ ⵓⵔⵣⵓ ⵏⵏⴽ ⴰⴽⴷ ⴽⵔⴰⵢⴳⴰⵜⵜ ⵜⴰⴱⵔⴰⵜ.",
    "just_now": "ⴷⵖⵉ ⵏⵏⵉⵜ",
    "notification_emails_stopped": "ⴱⴷⴷⵏ ⵉⵙⵓⴼⵔⵉⵜⵏ ⵏ ⵉⵎⴰⵢⵍ !",
    "destroy_account": "ⴽⴽⵙ ⴰⵎⵉⴹⴰⵏ ⵏⵏⴽ",
    "has_posted_in": "ⵉⵜⵜⵡⴰⴼⵙⵔ ⴳ",
    "has_commented_on": "ⵉⵙⴽⵔ ⴰⵅⴼⴰⵡⴰⵍ ⵅⴼ",
    "has_left": "ⵉⴼⴼⵖ",
    "the_message_from": "ⵜⴰⴱⵔⴰⵜ ⵙⴳ",
    "in": "ⴳ",
    "reply": "ⵔⴰⵔ",
    "public_link": "ⴰⵙⵖⵓⵏ ⴰⵎⴰⵜⴰⵢ",
    "default_group": "ⵜⴰⴳⵔⵓⵎⵎⴰ ⵜⴰⵎⵔⴷⵓⵜ",
    "are_you_sure": "ⵉⵙ ⵜⵃⵇⵇⵉⴷ?",
    "users": "ⵉⵏⵙⵙⵎⵔⴰⵙ",
    "share_message": "ⴱⴹⵓ ⵜⴰⴱⵔⴰⵜ ⴰⴼ",
    "multiple_photos_upload": "ⵜⴰⵣⴳⵍⵜ. ⴰⵍⵙ ⴰⴳⴰⵎ ⵏ ⵜⵡⵍⴰⴼⵉⵏ ⵉⵛⵜ ⵙ ⵢⵉⵛⵜ (ⵉⵣⵎⵔ ⴰⴷ ⵢⵉⵍⵉ ⵓⵢⴰⴷ ⴷⵉ ⴽⵔⴰ ⵏ ⵉⵎⵙⵙⴰⵔⴰⵜⵏ).",
    "error_new_message": "ⵜⴰⵣⴳⵍⵜ ⴳ ⵓⵙⵉⴼⴹ ⵏ ⵜⴱⵔⴰⵜ ⵏⵏⴽ.",
    "cancel_write": "ⵉⵙ ⵜⵃⵇⵇⵉⴷ ⵓⵔ ⵜⵔⵉⴷ ⴰⴷ ⵜⴰⵔⵉⴷ ⴽⵔⴰ ⵏ ⵜⴱⵔⴰⵜ ⵜⴰⵎⴰⵢⵏⵓⵜ?",
    "empty_message": "ⵜⵅⵡⴰ ⵜⴱⵔⴰⵜ ⵓⵔ ⵉⵣⵔⵉ ⵓⵙⵉⴼⴹ ⵏⵏⵙ !",
    "group_share_choice": "ⵎⴰⵜⵜⴰ ⵜⴳⵔⵓⵎⵎⴰ ⵏⵏⴰ ⵜⵔⵉⴷ ⴰⴷ ⴰⵙ ⵜⵙⵙⵉⴼⴹⴷ ⵜⴰⴱⵔⴰⵜ ⴰⴷ?",
    "destroy_account_explain": "ⵎⵛ ⵜⴽⴽⵙⴷ ⴰⵎⵉⴹⴰⵏ ⵏⵏⴽ, ⵓⵙⴰⵔ ⴰⴷ ⴷ ⵜⴰⵖⵓⵍⴷ. ⵃⵇⵇⴰ ⵙ ⵓⵢⴰⴷ.",
    "replies_one": "{{count}} ⵉⵔⴰⵔ",
    "error_upload": "ⵜⴰⵣⴳⵍⵜ ⴳ ⵡⴰⴳⴰⵎ",
    "add_bookmark": "ⵔⵏⵓ ⴳ ⵜⵙⵏⴰ ⵏ ⵜⵎⵉⵜⴰⵔ",
    "parent_message": "ⵜⴰⴱⵔⴰⵜ ⵏ ⵉⵎⴰⵔⴰⵡⵏ",
    "remove_bookmark": "ⴽⴽⵙ ⵜⴰⵙⵏⴰ ⵏ ⵜⵎⵉⵜⴰⵔ",
    "bookmarks": "ⵜⴰⵙⵏⴰ ⵏ ⵜⵎⵉⵜⴰⵔ",
    "reset_api_key": "ⵙⵏⴼⵍ ⵜⴰⵙⴰⵔⵓⵜ ⵏ API (ⵔⴰⴷ ⵉⵜⵜⵡⴰⴽⴽⵙ ⵓⵎⵢⴰⵡⴰⴹ ⵏⵏⴽ)",
    "video_not_ready": "ⵉⵙⵓⵍ ⵓⵔ ⵉⵡⵊⵉⴷ ⵓⴼⵉⴷⵢⵓ.",
    "bookmark_added": "ⵜⵓⴷⴼ ⵜⴱⵔⴰⵜ ⵖⵔ ⵜⵙⵏⴰ ⵏ ⵜⵎⵉⵜⴰⵔ !",
    "bookmark_removed": "ⵜⴻⵜⵜⵡⴰⴽⴽⵙ ⵜⴱⵔⴰⵜ ⵙⴳ ⵜⵙⵏⴰ ⵏ ⵜⵎⵉⵜⴰⵔ !",
    "upload_audio": "",
    "audio_not_ready": ""
}
