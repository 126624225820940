{
    "next_coms": "Ver os próximos comentários",
    "ago": "{{duration}} atrás",
    "new_password_placeholder": "nova senha",
    "confirm_password_placeholder": "confirmar senha",
    "connect": "Conexão",
    "submit": "Enviar",
    "text_placeholder": "Escreva sua mensagem aqui",
    "upload_video": "Adicionar um vídeo",
    "upload_music": "Adicionar música",
    "upload_pdf": "Adicionar PDF",
    "login": "Entrar",
    "login_input": "Escolha um login",
    "Invalid login/password": "Login e/ou senha inválidos",
    "edit": "Editar",
    "ask_delete_message": "Deletar essa mensagem?",
    "passwords_dont_match": "As senhas não coincidem",
    "invitation_welcome": "Bem-vindo ao Zusam. Registre-se antes de entrar no grupo.",
    "create_the_group": "Criar o grupo",
    "group_updated": "O grupo foi atualizado.",
    "cancel_write": "Você realmente deseja cancelar a criação de uma nova mensagem?",
    "just_now": "Agora mesmo",
    "hourly": "Por Hora",
    "destroy_account": "Deletar sua conta",
    "are_you_sure": "Você tem certeza?",
    "share_message": "compartilhe a mensagem",
    "has_commented_on": "comentou em",
    "in": "em",
    "lang": "idioma",
    "publish_in_group": "publicar no grupo",
    "remove_bookmark": "Remover mensagem dos favoritos",
    "api_key": "Chave API",
    "video_not_ready": "O vídeo ainda não está pronto.",
    "previous_coms": "Ver comentários anteriores",
    "groups": "Grupos",
    "login_placeholder": "e-mail",
    "password_placeholder": "senha",
    "title_placeholder": "Título da sua mensagem (opcional)",
    "upload_image": "Adicionar fotos",
    "logout": "Sair da sessão",
    "add_date": "Adicionar uma data",
    "settings": "Configurações",
    "email": "E-mail",
    "password": "Senha",
    "name": "Nome",
    "email_input": "Preencha seu endereço de e-mail aqui",
    "name_input": "Escolher um nome",
    "save_changes": "Salvar alterações",
    "password_input": "Escreva uma nova senha aqui",
    "delete": "Deletar",
    "account": "Conta",
    "forgot_password": "Esqueceu sua senha?",
    "password_reset_mail_sent": "Procure o e-mail de redefinição de senha em sua caixa de entrada.",
    "User not found": "Usuário não encontrado",
    "reset_password_title": "Redefinir sua senha",
    "error": "Um erro ocorreu",
    "invitation_link": "Link de convite",
    "reset_invitation_link": "Redefinir o link de convite",
    "signup": "Registrar-se",
    "create_a_group": "Criar um grupo",
    "quit_group": "Sair do grupo",
    "settings_updated": "Suas configurações foram atualizadas",
    "group_left": "Você saiu do grupo.",
    "multiple_photos_upload": "Ocorreu um erro. Tente fazer o upload das imagens uma a uma (isso pode ocorrer em alguns navegadores)",
    "error_new_message": "Um erro ocorreu ao enviar sua mensagem.",
    "error_upload": "Ocorreu um erro durante o envio",
    "empty_message": "A mensagem está vazia e não pode ser enviada.",
    "cancel": "Cancelar",
    "notification_emails": "Notificações de e-mail",
    "none": "Nenhuma",
    "daily": "Diariamente",
    "weekly": "Semanalmente",
    "monthly": "Mensal",
    "notification_emails_stopped": "As notificações de e-mail foram interrompidas!",
    "group_share_choice": "Para qual grupo você deseja enviar essa mensagem?",
    "public_link": "link público",
    "default_group": "Grupo padrão",
    "destroy_account_explain": "Depois de excluir sua conta, não há como voltar atrás. Por favor, tenha certeza.",
    "users": "usuários",
    "has_posted_in": "postou em",
    "has_joined": "entrou",
    "has_left": "saiu",
    "the_message_from": "a mensagem de",
    "reply": "responder",
    "replies_one": "{{count}} responder",
    "replies_other": "{{count}} respostas",
    "search_in_group": "procurar neste grupo",
    "changed_group_name": "mudou o nome do grupo",
    "to": "para",
    "notifications": "notificações",
    "mark_all_as_read": "marcar todos como lido",
    "search_without_result": "Sua pesquisa não correspondeu a nenhuma mensagem.",
    "add_bookmark": "Adicionar mensagem aos favoritos",
    "bookmarks": "Favoritos",
    "reset_api_key": "Redefinir a chave API (você será desconectado)",
    "bookmark_added": "Mensagem adicionada aos favoritos.",
    "bookmark_removed": "Mensagem removida dos favoritos.",
    "parent_message": "Mensagem relacionada",
    "audio_not_ready": "O áudio ainda não está pronto.",
    "upload_audio": "Adicionar um arquivo de áudio."
}
