{
    "add_bookmark": "Добавить сообщение в закладки",
    "add_date": "Добавить дату",
    "api_key": "Ключ API",
    "are_you_sure": "Вы уверены?",
    "ask_delete_message": "Вы уверены, что хотите удалить это сообщение?",
    "audio_not_ready": "Аудио еще не готово.",
    "bookmark_added": "Сообщение добавлено в закладки !",
    "bookmark_removed": "Сообщение удалено из закладок !",
    "bookmarks": "закладки",
    "cancel_write": "Вы действительно хотите отменить отправку нового сообщения?",
    "changed_group_name": "изменил название группы",
    "confirm_password_placeholder": "подтвердите пароль",
    "create_a_group": "Создать группу",
    "default_group": "Группа по умолчанию",
    "delete": "Удалить",
    "destroy_account": "Удалить свой аккаунт",
    "edit": "Редактировать",
    "email_input": "Заполните свой адрес электронной почты здесь",
    "email": "Электронная почта",
    "empty_message": "Сообщение пустое и не может быть отправлено !",
    "error_new_message": "При отправке вашего сообщения произошла ошибка.",
    "error_upload": "Во время загрузки произошла ошибка",
    "error": "Произошла ошибка",
    "forgot_password": "Вы забыли свой пароль?",
    "group_left": "Ты покинул группу !",
    "group_share_choice": "Какой группе вы хотите отправить это сообщение?",
    "groups": "Группы",
    "group_updated": "Группа была обновлена!",
    "has_commented_on": "прокомментировал",
    "has_joined": "присоединился",
    "has_left": "ушел",
    "has_posted_in": "разместил в",
    "Invalid login/password": "Неверный логин и/или пароль",
    "invitation_link": "Пригласительная ссылка",
    "invitation_welcome": "Добро пожаловать на Zusam! Пожалуйста, зарегистрируйтесь, прежде чем присоединиться к группе.",
    "in": "в",
    "lang": "язык",
    "login_placeholder": "электронная почта",
    "login": "Авторизоваться",
    "logout": "Выход из системы",
    "mark_all_as_read": "отметьте все как прочитанное",
    "multiple_photos_upload": "Произошла ошибка. Попробуйте загружать изображения по одному (это может произойти в некоторых браузерах)",
    "name_input": "Выбрать имя",
    "name": "Имя",
    "new_password_placeholder": "новый пароль",
    "notification_emails_stopped": "Уведомления по электронной почте остановлены !",
    "notification_emails": "Уведомления по электронной почте",
    "notifications": "уведомления",
    "password_input": "Введите новый пароль здесь",
    "password_placeholder": "пароль",
    "password_reset_mail_sent": "Электронное письмо со сбросом пароля было отправлено !",
    "passwords_dont_match": "Пароли не совпадают",
    "password": "Пароль",
    "public_link": "общедоступная ссылка",
    "publish_in_group": "опубликовать в группе",
    "quit_group": "Покинуть группу",
    "remove_bookmark": "Удалить сообщение из закладки",
    "reset_api_key": "Сбросьте ключ API (вы будете отключены)",
    "reset_invitation_link": "Сбросить ссылку на приглашение",
    "save_changes": "Сохранить изменения",
    "search_in_group": "поиск в этой группе",
    "search_without_result": "Ваш поиск не выявил ни одного сообщения.",
    "settings_updated": "Ваши настройки были обновлены",
    "settings": "Настройки",
    "share_message": "поделиться сообщением",
    "signup": "Войти",
    "text_placeholder": "Напишите свое сообщение здесь",
    "the_message_from": "сообщение от",
    "title_placeholder": "Заголовок вашего сообщения (опционально)",
    "to": "к",
    "upload_audio": "Добавить аудиофайл.",
    "upload_image": "Добавить фотографии",
    "upload_music": "Добавить музыку",
    "upload_pdf": "Добавить pdf-файл",
    "upload_pdf": "Добавить PDF-файл",
    "upload_video": "Добавить видео",
    "User not found": "Пользователь не найден",
    "users": "пользователи",
    "video_not_ready": "Видео еще не готово."
}
