{
    "previous_coms": "Zie vorige",
    "next_coms": "Zie volgende",
    "ago": "{{duration}} geleden",
    "groups": "Groepen",
    "login_placeholder": "e-mail",
    "password_placeholder": "wachtwoord",
    "new_password_placeholder": "nieuw wachtwoord",
    "confirm_password_placeholder": "bevestig wachtwoord",
    "connect": "Verbind",
    "submit": "Verstuur",
    "title_placeholder": "Titel van jouw bericht (optioneel)",
    "text_placeholder": "Schrijf jouw bericht hier",
    "upload_image": "Voeg foto's toe",
    "upload_video": "Voeg filmpje toe",
    "upload_music": "Voeg muziek toe",
    "upload_pdf": "Voeg PDF toe",
    "add_date": "Voeg een datum toe",
    "logout": "Afmelden",
    "settings": "Instellingen",
    "name": "Naam",
    "name_input": "Kies een naam",
    "login": "Aanmelden",
    "login_input": "Kies een aanmeldingsnaam",
    "email": "E-mail",
    "email_input": "Voeg uw e-mail adres hier in",
    "save_changes": "Wijzigingen opslaan",
    "password": "Wachtwoord",
    "password_input": "Kies een nieuw wachtwoord",
    "Invalid login/password": "Ongeldige aanmeldnaam/wachtwoord combinatie",
    "edit": "Bewerken",
    "delete": "Verwijderen",
    "ask_delete_message": "Verwijder dit bericht?",
    "account": "Account",
    "forgot_password": "Wachtwoord vergeten?",
    "password_reset_mail_sent": "Zoek in uw inbox naar de wachtwoordreset e-mail.",
    "User not found": "Gebruiker niet gevonden",
    "reset_password_title": "Reset uw wachtwoord",
    "error": "Er is een fout opgetreden",
    "passwords_dont_match": "Wachtwoorden komen niet overeen",
    "invitation_link": "Uitnodigings link",
    "reset_invitation_link": "Reset uitnodiging link",
    "invitation_welcome": "Welkom op Zusam. Gelieve aan te melden vooraleer in een groep in te schrijven.",
    "signup": "Inschrijven",
    "create_a_group": "Maak een groep",
    "create_the_group": "Maak de groep",
    "quit_group": "Verlaat de groep",
    "settings_updated": "Uw instellingen werden aangepast",
    "group_updated": "De groep werd aangepast.",
    "group_left": "U heeft de groep verlaten.",
    "multiple_photos_upload": "Er is een fout opgetreden. Probeer foto's één voor één op te laden",
    "error_new_message": "Er is een fout opgetreden bij het doorsturen van uw bericht.",
    "error_upload": "Er is een fout opgetreden bij het uploaden",
    "cancel_write": "Bent u zeker dat u dit bericht wil annuleren?",
    "empty_message": "Dit bericht is leeg en kan niet worden verzonden.",
    "cancel": "Annuleren",
    "just_now": "Net",
    "notification_emails": "E-mail meldingen",
    "none": "Niets",
    "hourly": "Elk uur",
    "daily": "Elke dag",
    "weekly": "Elke week",
    "monthly": "Elke maand",
    "notification_emails_stopped": "E-mail meldingen zijn gestopt !",
    "group_share_choice": "Naar welke groep wenst u dit bericht te versturen?",
    "public_link": "publieke link",
    "default_group": "Standaard groep",
    "destroy_account": "Verwijder jouw account",
    "destroy_account_explain": "Indien u uw account verwijdert, kan deze niet meer worden teruggehaald.",
    "are_you_sure": "Bent u zeker?",
    "share_message": "Deel bericht",
    "users": "gebruikers",
    "has_posted_in": "heeft gepost in",
    "has_commented_on": "heeft commentaar gegeven op",
    "has_joined": "is erbij gekomen",
    "has_left": "is weggegaan",
    "the_message_from": "het bericht van",
    "in": "in",
    "lang": "taal",
    "reply": "antwoorden",
    "replies_one": "{{count}} antwoord",
    "replies_other": "{{count}} antwoorden",
    "publish_in_group": "gepubliseerd in de groep",
    "search_in_group": "zoek in deze groep",
    "changed_group_name": "verandert de groepsnaam",
    "to": "aan",
    "notifications": "notificaties",
    "mark_all_as_read": "markeer als gelezen",
    "search_without_result": "Er werd geen enkel bericht gevonden met deze criteria.",
    "add_bookmark": "Bookmark bericht",
    "remove_bookmark": "Unbookmark bericht",
    "bookmarks": "bookmarks",
    "api_key": "API key",
    "reset_api_key": "Reset de API key (u zal worden gedeconnecteerd)",
    "video_not_ready": "Video is nog niet klaar.",
    "bookmark_added": "Bericht toegevoegd aan bladwijzers.",
    "bookmark_removed": "Bericht verwijderd uit bladwijzers.",
    "parent_message": "Parent bericht",
    "upload_audio": "Voeg een audiobestand toe.",
    "audio_not_ready": "Audio is nog niet klaar."
}
