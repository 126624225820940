{
    "login_placeholder": "e-post",
    "password_placeholder": "passord",
    "new_password_placeholder": "nytt passord",
    "add_date": "Legg til en dato",
    "logout": "Logg ut",
    "upload_pdf": "Legg til PDF",
    "email_input": "Fyll inn e-postadressen din her",
    "save_changes": "Lagre endringer",
    "password": "Passord",
    "login_input": "Velg innloggingsnavn",
    "password_input": "Skriv det nye passordet ditt her",
    "ask_delete_message": "Slett meldingen?",
    "account": "Konto",
    "passwords_dont_match": "Passordene samsvarer ikke",
    "reset_invitation_link": "Tilbakestill invitasjonslenke",
    "password_reset_mail_sent": "Se etter e-post om tilbakestilling av passord i innboksen din.",
    "invitation_welcome": "Velkommen til Zusam. Registrer deg før du tar del i gruppen.",
    "group_updated": "Gruppen ble oppdatert.",
    "error_new_message": "En feil inntraff ved forsendelse av meldingen din",
    "just_now": "Akkurat nå",
    "none": "Ingen",
    "cancel_write": "Forkast den nye meldingen?",
    "group_share_choice": "Hvilken gruppe vil du sende denne meldingen til?",
    "share_message": "del meldingen",
    "has_posted_in": "har postet i",
    "destroy_account_explain": "Når du sletter kontoen din er permanent. Vær sikker i din sak.",
    "in": "i",
    "has_joined": "har tatt del i",
    "has_left": "har forlatt",
    "has_commented_on": "har kommentert",
    "publish_in_group": "offentliggjør i gruppen",
    "search_in_group": "søk i denne gruppen",
    "changed_group_name": "endret gruppenavnet",
    "to": "til",
    "notifications": "merknader",
    "remove_bookmark": "Fjern meldingsbokmerke",
    "search_without_result": "Søket ditt er resultatløst.",
    "bookmarks": "bokmerker",
    "reset_api_key": "Tilbakestill API-nøkkelen (du vil bli frakoblet)",
    "bookmark_added": "Melding bokmerket.",
    "upload_audio": "Legg til en lydfil.",
    "previous_coms": "Vis tidligere kommentarer",
    "groups": "Grupper",
    "connect": "Tilkobling",
    "next_coms": "Vis videre kommentarer",
    "ago": "{{duration}} siden",
    "submit": "Send inn",
    "text_placeholder": "Skriv meldingen din her",
    "confirm_password_placeholder": "bekreft",
    "title_placeholder": "Navn på meldingen (valgfritt)",
    "upload_image": "Legg til bilder",
    "upload_video": "Legg til video",
    "upload_music": "Legg til musikk",
    "forgot_password": "Glemt passordet?",
    "settings": "Innstillinger",
    "name": "Navn",
    "signup": "Registrer deg",
    "name_input": "Velg et navn",
    "login": "Logg inn",
    "email": "E-post",
    "edit": "Rediger",
    "delete": "Slett",
    "error": "En feil oppstod",
    "User not found": "Fant ikke brukeren",
    "public_link": "offentlig lenke",
    "reset_password_title": "Tilbakestill passordet ditt",
    "invitation_link": "Invitasjonslenke",
    "create_a_group": "Opprett en gruppe",
    "create_the_group": "Opprett gruppen",
    "group_left": "Du forlot gruppen.",
    "quit_group": "Forlat gruppen",
    "destroy_account": "Slett kontoen din",
    "lang": "språk",
    "replies_other": "{{count}} svar",
    "daily": "Daglig",
    "weekly": "Ukentlig",
    "monthly": "Månedlig",
    "default_group": "Forvalgt gruppe",
    "are_you_sure": "Er du sikker?",
    "the_message_from": "meldingen fra",
    "users": "brukere",
    "reply": "svar",
    "replies_one": "{{count}} svar",
    "video_not_ready": "Videoen er ikke klar enda.",
    "api_key": "API-nøkkel",
    "audio_not_ready": "Lyden er ikke klar enda.",
    "Invalid login/password": "Ugyldig brukernavn/eller passord",
    "mark_all_as_read": "marker alt som lest",
    "add_bookmark": "Bokmerk melding",
    "settings_updated": "Innstillingene dine ble oppdatert",
    "hourly": "Timevist",
    "multiple_photos_upload": "En feil inntraff. Prøv å laste opp bilder enkeltvis. (Nødvendig i noen nettlesere.)",
    "error_upload": "En feil inntraff under opplasting",
    "cancel": "Avbryt",
    "empty_message": "Meldingen er tom og kan ikke sendes.",
    "notification_emails": "E-postmerknader",
    "notification_emails_stopped": "E-postmerknader stoppet.",
    "bookmark_removed": "Meldingsbokmerke fjernet.",
    "parent_message": "Inneholdende melding"
}
