{
    "previous_coms": "முந்தைய கருத்துகளைப் பார்க்கவும்",
    "upload_music": "இசையைச் சேர்க்கவும்",
    "upload_pdf": "PDF ஐச் சேர்க்கவும்",
    "add_date": "ஒரு தேதியைச் சேர்க்கவும்",
    "logout": "விடுபதிகை",
    "settings": "அமைப்புகள்",
    "login_input": "உள்நுழைவைத் தேர்வுசெய்க",
    "reply": "பதில்",
    "replies_one": "{{count}} பதில்",
    "bookmark_added": "செய்தி புக்மார்க்கு செய்யப்பட்டது.",
    "next_coms": "அடுத்த கருத்துகளைப் பார்க்கவும்",
    "ago": "{{காலம்}} முன்பு",
    "groups": "குழுக்கள்",
    "login_placeholder": "மின்னஞ்சல்",
    "password_placeholder": "கடவுச்சொல்",
    "new_password_placeholder": "புதிய கடவுச்சொல்",
    "confirm_password_placeholder": "கடவுச்சொல்லை உறுதிப்படுத்தவும்",
    "connect": "இணைப்பு",
    "submit": "சமர்ப்பிக்கவும்",
    "title_placeholder": "உங்கள் செய்தியின் தலைப்பு (விரும்பினால்)",
    "text_placeholder": "உங்கள் செய்தியை இங்கே எழுதுங்கள்",
    "upload_image": "புகைப்படங்களைச் சேர்க்கவும்",
    "upload_video": "வீடியோவைச் சேர்க்கவும்",
    "name": "பெயர்",
    "name_input": "ஒரு பெயரைத் தேர்வுசெய்க",
    "login": "புகுபதிகை",
    "email": "மின்னஞ்சல்",
    "email_input": "உங்கள் மின்னஞ்சல் முகவரியை இங்கே நிரப்பவும்",
    "save_changes": "மாற்றங்களை சேமியுங்கள்",
    "password": "கடவுச்சொல்",
    "password_input": "புதிய கடவுச்சொல்லை இங்கே எழுதுங்கள்",
    "Invalid login/password": "தவறான உள்நுழைவு மற்றும்/அல்லது கடவுச்சொல்",
    "edit": "தொகு",
    "delete": "அழி",
    "ask_delete_message": "இந்த செய்தியை நீக்கவா?",
    "account": "கணக்கு",
    "forgot_password": "உங்கள் கடவுச்சொல்லை மறந்து விட்டீர்களா?",
    "password_reset_mail_sent": "உங்கள் இன்பாக்சில் கடவுச்சொல் மீட்டமைப்பு மின்னஞ்சலைத் தேடுங்கள்.",
    "User not found": "பயனர் கண்டுபிடிக்கப்படவில்லை",
    "reset_password_title": "உங்கள் கடவுச்சொல்லை மீட்டமைக்க",
    "public_link": "பொது இணைப்பு",
    "error": "பிழை ஏற்பட்டது",
    "passwords_dont_match": "கடவுச்சொற்கள் பொருந்தவில்லை",
    "invitation_link": "அழைப்பிதழ் இணைப்பு",
    "reset_invitation_link": "அழைப்பிதழ் இணைப்பை மீட்டமைக்கவும்",
    "invitation_welcome": "சூசாமில் வரவேற்கிறோம். குழுவில் சேருவதற்கு முன் பதிவுபெறுக.",
    "signup": "பதிவு செய்க",
    "create_a_group": "ஒரு குழுவை உருவாக்கவும்",
    "create_the_group": "குழுவை உருவாக்கவும்",
    "quit_group": "குழுவை விட்டு விடுங்கள்",
    "settings_updated": "உங்கள் அமைப்புகள் புதுப்பிக்கப்பட்டன",
    "group_updated": "குழு புதுப்பிக்கப்பட்டது.",
    "group_left": "நீங்கள் குழுவை விட்டு வெளியேறினீர்கள்.",
    "multiple_photos_upload": "பிழை ஏற்பட்டது. படங்களை ஒவ்வொன்றாக பதிவேற்ற முயற்சிக்கவும் (இது சில உலாவிகளில் நிகழலாம்)",
    "error_new_message": "உங்கள் செய்தியை அனுப்பும்போது பிழை ஏற்பட்டது.",
    "error_upload": "பதிவேற்றத்தின் போது பிழை ஏற்பட்டது",
    "cancel_write": "புதிய செய்தியை எழுதுவதை ரத்து செய்ய விரும்புகிறீர்களா?",
    "empty_message": "செய்தி காலியாக உள்ளது மற்றும் அனுப்ப முடியாது.",
    "cancel": "ரத்துசெய்",
    "just_now": "இப்போது",
    "notification_emails": "மின்னஞ்சல் அறிவிப்புகள்",
    "none": "எதுவுமில்லை",
    "hourly": "மணிநேரத்திற்கு",
    "daily": "நாள்தோறும்",
    "weekly": "வாராந்திர",
    "monthly": "மாதாந்திர",
    "notification_emails_stopped": "மின்னஞ்சல் அறிவிப்புகள் நிறுத்தப்பட்டன!",
    "group_share_choice": "இந்த செய்தியை எந்த குழுவிற்கு அனுப்ப விரும்புகிறீர்கள்?",
    "default_group": "இயல்புநிலை குழு",
    "destroy_account": "உங்கள் கணக்கை நீக்கு",
    "destroy_account_explain": "உங்கள் கணக்கை நீக்கியதும், திரும்பிச் செல்வதில்லை. தயவுசெய்து உறுதியாக இருங்கள்.",
    "are_you_sure": "நீ சொல்வது உறுதியா?",
    "share_message": "செய்தியைப் பகிரவும்",
    "users": "பயனர்கள்",
    "has_posted_in": "இடுகையிடப்பட்டுள்ளது",
    "has_commented_on": "கருத்து தெரிவித்துள்ளது",
    "has_joined": "சேர்ந்துள்ளார்",
    "has_left": "போய் விட்டது",
    "the_message_from": "இருந்து செய்தி",
    "in": "இல்",
    "lang": "மொழி",
    "replies_other": "{{count}} பதில்கள்",
    "publish_in_group": "குழுவில் வெளியிடுங்கள்",
    "search_in_group": "இந்த குழுவில் தேடுங்கள்",
    "changed_group_name": "குழுவின் பெயரை மாற்றியது",
    "to": "பெறுநர்",
    "notifications": "அறிவிப்புகள்",
    "mark_all_as_read": "அனைத்தையும் வாசிக்கப்பட்டதாக அடையாளமிடு",
    "search_without_result": "உங்கள் தேடல் எந்த செய்திக்கும் பொருந்தவில்லை.",
    "add_bookmark": "புக்மார்க்கு செய்தி",
    "remove_bookmark": "புத்தகமரம் செய்தி",
    "bookmarks": "புக்மார்க்குகள்",
    "api_key": "பநிஇ விசை",
    "reset_api_key": "பநிஇ விசையை மீட்டமைக்கவும் (நீங்கள் துண்டிக்கப்படுவீர்கள்)",
    "video_not_ready": "வீடியோ இன்னும் தயாராக இல்லை.",
    "bookmark_removed": "செய்தி புக்மார்க்கு நீக்கப்பட்டது.",
    "parent_message": "பெற்றோர் செய்தி",
    "audio_not_ready": "ஆடியோ இன்னும் தயாராக இல்லை.",
    "upload_audio": "ஆடியோ கோப்பைச் சேர்க்கவும்."
}
