{
    "hourly": "Hodinová",
    "next_coms": "Shlédnout další komentáře",
    "ago": "před {{duration}}",
    "login_placeholder": "e-mail",
    "password_placeholder": "heslo",
    "new_password_placeholder": "nové heslo",
    "confirm_password_placeholder": "potvrdit heslo",
    "connect": "Připojení",
    "submit": "Odeslat",
    "title_placeholder": "Nadpis zprávy (volitelně)",
    "text_placeholder": "Zde napište svou zprávu",
    "upload_pdf": "Nahrát PDF",
    "upload_image": "Nahrát fotky",
    "upload_video": "Nahrát video",
    "upload_music": "Nahrát hudbu",
    "add_date": "Zadat datum",
    "logout": "Odhlásit",
    "settings": "Nastavení",
    "name": "Jméno",
    "name_input": "Vybrat jméno",
    "login": "Login",
    "login_input": "Vybrat login",
    "email": "E-mail",
    "email_input": "Vyplňte sem svůj e-mail",
    "save_changes": "Uložit změny",
    "password": "Heslo",
    "password_input": "Vyplňte sem nové heslo",
    "Invalid login/password": "Neplatný login a/nebo heslo",
    "edit": "Upravit",
    "delete": "Smazat",
    "ask_delete_message": "Opravdu chcete smazat tuto zprávu?",
    "account": "Účet",
    "forgot_password": "Zapoměli jste své heslo?",
    "password_reset_mail_sent": "E-mail pro obnovu hesla byl odeslán!",
    "User not found": "Uživatel nenalezen",
    "reset_password_title": "Resetovat heslo",
    "error": "Došlo k chybě",
    "passwords_dont_match": "Hesla se neshodují",
    "invitation_link": "Odkaz s pozvánkou",
    "reset_invitation_link": "Resetovat odkaz s pozvánkou",
    "invitation_welcome": "Vítejte na Zusam! Před vstupem do skupiny se přihlašte.",
    "signup": "Založit účet",
    "create_a_group": "Vytvořit skupinu",
    "create_the_group": "Vytvořit tuto skupinu",
    "quit_group": "Opustit skupinu",
    "settings_updated": "Nastavení byla aktualizována",
    "group_updated": "Skupina byla aktualizována!",
    "group_left": "Opustili jste tuto skupinu!",
    "multiple_photos_upload": "Došlo k chybě. Zkuste nahrát obrázky jeden po druhém (toto se občas v některých prohlížečích stane).",
    "error_new_message": "Došlo k chybě během odesílání zprávy.",
    "error_upload": "Došlo k chybě během nahrávání",
    "cancel_write": "Opravdu chcete ukončit psaní nové zprávy?",
    "empty_message": "Prázdnou zprávu nelze odeslat!",
    "cancel": "Zrušit",
    "just_now": "Právě teď",
    "notification_emails": "E-mailová upozornění",
    "monthly": "Měsíční",
    "notification_emails_stopped": "E-mailová upozornění zastavena!",
    "group_share_choice": "Do které skupiny chcete poslat tuto zprávu?",
    "public_link": "veřejný odkaz",
    "default_group": "Výchozí skupina",
    "destroy_account": "Smazat účet",
    "destroy_account_explain": "Smazání účtu nelze vrátit zpět. Buďte si opravdu jisti.",
    "are_you_sure": "Jste si opravdu jisti?",
    "share_message": "sdílet zprávu",
    "previous_coms": "Shlédnout předchozí komentáře",
    "users": "uživatelé",
    "has_posted_in": "zveřejnili v",
    "has_commented_on": "komentovali v",
    "has_joined": "vstoupili",
    "has_left": "opustili",
    "the_message_from": "zprávu od",
    "in": "v",
    "lang": "jazyk",
    "reply": "odpověď",
    "replies_one": "{{count}} odpověď",
    "replies_other": "{{count}} odpovědí",
    "publish_in_group": "zveřejnit ve skupině",
    "search_in_group": "hledat ve skupině",
    "changed_group_name": "změnili jméno skupiny",
    "to": "na",
    "notifications": "upozornění",
    "mark_all_as_read": "označit vše jako přečtené",
    "search_without_result": "Hledání nevrátilo žadné odpovídající zprávy.",
    "add_bookmark": "Přidat zprávu do záložek",
    "remove_bookmark": "Smazat zprávu ze záložek",
    "bookmarks": "záložky",
    "api_key": "API klíč",
    "reset_api_key": "Resetovat API klíč (budete odpojeni)",
    "video_not_ready": "Video ještě není připraveno.",
    "bookmark_added": "Zpráva přidána do záložek!",
    "bookmark_removed": "Zpráva odstraněna ze záložek!",
    "parent_message": "Nadřazená zpráva",
    "groups": "Skupiny",
    "none": "Žádná",
    "daily": "Denní",
    "weekly": "Týdenní",
    "upload_audio": "",
    "audio_not_ready": ""
}
